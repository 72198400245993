<template>
    <div class="whome">
        <Header :title="$t('head.invite')" :progress="3" :progress_curr="1">
            <!--template v-slot:prev>
                Prev
            </template>
            <template v-slot:after>
                After
            </template-->
        </Header>
        <div>
            <img src="@/assets/images/logo.svg" width="80" height="80" />
        </div>
        <h1>{{ $t('terms.title') }}</h1>
        <p class="subtitle" v-html="subtitle"></p>
        <ul>
            <li :class="{check: terms[0]}" @click="onClickTerms(0)">
                Terms and conditions of use 1
                <span>Detail</span>
            </li>
            <li :class="{check: terms[1]}" @click="onClickTerms(1)">
                Terms and conditions of use 2
                <span>Detail</span>
            </li>
            <li :class="{check: terms[2]}" @click="onClickTerms(2)">
                Terms and conditions of use 3
                <span>Detail</span>
            </li>
            <li :class="{check: terms[3]}" @click="onClickTerms(3)">
                Terms and conditions of use 4
                <span>Detail</span>
            </li>
        </ul>
        <button @click="onClickButton()" class="hover">
            <template v-if="!isAllCheck()">{{ $t('terms.agreeBtn') }}</template>
            <template v-else>Next</template>
        </button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from '@/components/Header.vue'

export default
{
    name: 'sign-terms',
    data()
    {
        return {
            terms: [false, false, false, false]
        };
    },
    computed:
    {
        ...mapGetters({
            paramData: 'getParamData',
            contractInfo: 'getContractInfo',
        }),
        subtitle()
        {
            let html = this.$t('terms.subtitle', {
                    invitee: this.contractInfo.participant.me.name,
                    host: this.contractInfo.participant.from.name,
                    type: this.$t('type.' + this.contractInfo.product.id)
            });
            return html.replace(/<span>/g, "<span style='font-weight:bold;color:#1255BA'>");
        }
    },
    methods:
    {
        ...mapActions(["actionTermAgree"]),

        isAllCheck()
        {
            for ( var i = 0 ; i < this.terms.length; i++ )
            {
                if ( !this.terms[i] )
                    return false;
            }
            return true;
        },
        onClickTerms(index)
        {
            this.terms[index] = !this.terms[index];
        },
        async onClickButton()
        {
            if ( this.isAllCheck() )
            {
                try
                {
                    let state = await this.$utils.checkGeolocationPermission();
                    if ( state == 'prompt' )
                    {
                        await this.$utils.getLocation();
                        await this.$utils.checkGeolocationPermission();
                    }

                    //if ( state != 'granted' )
                    //{
                    //    console.log(state);
                    //    alert(this.$t('terms.permition'));
                    //    return;
                    //}

                    this.actionTermAgree();
                    this.$router.push('/sign/upload');
                }
                catch ( err )
                {
                    console.error(err);
                    alert(this.$t('terms.permition'));
                }
            }
            else
            {
                for ( var i = 0 ; i < this.terms.length; i++ )
                {
                    this.terms[i] = true;
                }
            }
        }
    },
    created()
    {
        console.log(this.paramData);
        console.log(this.contractInfo);
    },
    components:
    {
        Header
    }
}
</script>

<style scoped>
.whome { position: absolute; top: 0; left:0; right:0; height: 100%; }
.whome .subtitle { margin-top: 16px; }

.whome ul { margin: 16px 0; list-style: none; }
.whome ul li { border: 0.5px solid #7E8185; border-radius: 8px; height: 65px; margin: 8px 0; font-weight: 700; color: #313336; line-height: 65px; padding-left: 50px; background-image: url(@/assets/images/checkbox_unchecked.svg); background-repeat: no-repeat; background-position: 15px; background-size: 24px; }
.whome ul li.check { background-color: #E3E5E8; background-image: url(@/assets/images/checkbox_checked.svg); }
.whome ul li span { float: right; padding-right: 15px; color: #7E8185; font-size: 14px; font-weight: 700; text-decoration: underline; }
.whome button { position: absolute; left: 0; right: 0; bottom: 16px;  }
</style>