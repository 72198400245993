<template>
    <div class="wupload">
        <Header :title="$t('head.invite')" :progress="3" :progress_curr="2">
            <template v-slot:after>
                <i class="close"></i>
            </template>
        </Header>

        <ul class="users">
            <li v-for="(item, index) in participants" :key="index">
                <i class="user" :style="getUserColor(index)"></i>
                <span>{{ item.name }}</span>
                <div :class="item.status">{{ $t('status.' + item.status) }}</div>
            </li>
        </ul>

        <div class="media">

            <div v-if="contractInfo.product.id == 'audio' || contractInfo.product.id == 'video' || contractInfo.product.id == 'mobile'">
                <div id="media-container" :class="[{audio: contractInfo.product.id == 'audio'}, {video: contractInfo.product.id == 'video' || contractInfo.product.id == 'mobile'}]"></div>
            </div>

            <div class="upload" v-if="contractInfo.product.id == 'upload'">
                <div v-for="(item, index) in contractInfo.files" :key="index" class="item" @click="onClickUploadFile(item)">
                    <i class="material-icons" :class="$utils.getFileType($utils.getFileExtension(item.name))">{{ $utils.getFileType($utils.getFileExtension(item.name)) }}</i>
                    <span style="padding-left: 5px;">{{ item.name }}</span>
                </div>
            </div>

        </div>

        <Tips :type="$t('upload.tips.type')"
              :title="$t('upload.tips.title')"
              :tips="[
                $t('upload.tips.tip1'),
                $t('upload.tips.tip2'),
                $t('upload.tips.tip3'),
                $t('upload.tips.tip4'),
              ]" class="tips" />

        <div class="buttons" v-if="!isSigned">
            <button @click="onClickReject()" class="reject">{{ $t('upload.rejectBtn') }}</button>
            <button @click="onClickSign()" class="sign hover">{{ $t('upload.signBtn') }}</button>
        </div>
        <div class="buttons wait" v-if="isSigned">
            <button class="wait">{{ $t('upload.signBtnWait') }}</button>
        </div>

        <div class="modalsheet" v-if="show_modal || isAnimating">
            <div class="back" @click="closeModal()"></div>
            <div class="sheet" :class="{ 'slide-in': show_modal, 'slide-out': !show_modal }" @animationend="handleAnimationEnd">
                <div class="line"></div>
                <h1>{{ $t('upload.sign.title') }}</h1>
                <template v-if="contractInfo.use_phone_cert == 'Y'">
                    <p style="margin-top: 8px;">{{ $t('upload.sign.subtitle') }}</p>
                    <div class="phonepad">
                        <div class="item">
                            <div class="inputbox" style="width:73%">
                                <span>+{{ phone.ncode }}-</span>
                                <input type="text" :placeholder="$t('upload.sign.phone')" v-model="phone.num" :readonly="(phone.step == 'code' && phone.remain > 0) || phone.step == 'verify'" />
                            </div>
                            <button :class="{'hover': phone.step == '' || (phone.step == 'code' && phone.remain <= 0)}" style="width:23%" @click="onClickPhoneCode()">
                                {{ phoneCodeName }}
                            </button>
                        </div>
                        <div class="item">
                            <div class="inputbox" style="width:59%">
                                <input type="text" :placeholder="$t('upload.sign.certno')" v-model="phone.certno" :readonly="phone.step == 'verify'" />
                            </div>
                            <button :class="{'hover': phone.step == 'code'}" style="width:37%"  @click="onClickPhoneVerify()">
                                {{ phone.step == 'verify' ? $t('upload.sign.phoneok') : $t('upload.sign.phonecert') }}
                            </button>
                        </div>
                    </div>
                </template>
                <p style="margin-top: 24px;">{{ $t('upload.sign.signmsg') }}</p>
                <div class="signpad">
                    <Vue3Signature ref="signature" :sigOption="signature_option" :disabled="phone.step != 'verify'" @mousedown="onClickSignMark" @touchstart="onClickSignMark" />
                    <!--div class="watermark" v-show="!signature_action" @mousedown="onClickSignMark">Your signature</div-->
                    <i class="circleclose close" @click="onSignatureClear()" v-show="signature_action"></i>
                </div>
                <div class="info"> 
                    <div>{{ current_time }}</div>
                    <div v-if="gps_info != 'X'">{{ gps_info }}</div>
                    <div v-else style="color:#C41919">{{ $t('upload.nogps') }}</div>
                </div>
                <button class="signbtn" :class="{'hover': signature_action}" @click="onClickProcess()">{{ $t('upload.sign.signBtn') }}</button>
            </div>
        </div>

        <div class="rejectsign" v-if="show_dialog">
            <div class="backpan" @click="show_dialog = false"></div>
            <div class="dialog">
                <div class="image"><img src="@/assets/images/contractCancel.svg" width="48" height="48"/></div>
                <div class="title">{{ $t('upload.reject.title') }}</div>
                <div class="subtitle" v-html="$t('upload.reject.subtitle')"></div>
                <div class="btns">
                    <button @click="show_dialog = false">{{ $t('upload.reject.cancel') }}</button>
                    <button class="red" @click="onClickRejectSign()">{{ $t('upload.reject.okbtn') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from '@/components/Header.vue'
import Tips from '@/components/Tips.vue'

export default
{
    name: 'sign-upload',
    data()
    {
        return {
            show_modal: false,
            isAnimating: false,
            show_dialog: false,

            signature_option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
                maxWidth: 2,
                minWidth: 2,
            },
            signature_action: false,

            phone: {
                ncode: '82',
                num: '',
                certno: '',
                step: '',
                expire: 0,
                remain: 0
            },
            isSigned: false,

            timer: undefined,
            current_time: '',
            gps_info: '',
        };
    },
    computed:
    {
        ...mapGetters({
            paramData: 'getParamData',
            contractInfo: 'getContractInfo',
            //contractSignStatus: 'getContractSignStatus',
        }),
        participants()
        {
            let participants = [];

            participants.push(this.contractInfo.participant.me);
            participants.push(this.contractInfo.participant.from);

            for ( var i = 0 ; i < this.contractInfo.participant.to.length ; i++ )
            {
                participants.push(this.contractInfo.participant.to[i]);
            }

            return participants;
        },
        phoneCodeName()
        {
            if ( this.phone.step == 'code' )
            {
                if ( this.phone.remain <= 0 ) 
                    return this.$t('upload.sign.resend');

                return this.phone.remain + 's';
            }

            return this.$t('upload.sign.code');
        },
    },
    methods:
    {
        ...mapActions(["actionContractInfo"]),

        getUserColor(index)
        {
            return 'background-color: ' + this.$utils.getParticipantIconColor(index);
        },
        phoneRemain()
        {
            const timestamp = Math.floor(Date.now() / 1000);
            this.phone.remain = this.phone.expire - timestamp;
            if ( this.phone.remain <= 0 ) return;

            setTimeout(() => {
                this.phoneRemain();
            }, 1000);
        },
        async playMediaFile(file)
        {
            const res = await this.$comm.RequestFILE(this.$i18n.locale, '/v1/download', {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                filename: file.name,
            });

            if ( res ) {
                if ( res instanceof Blob ) {
                    const mediaUrl = URL.createObjectURL(res);

                    const isAudio = file.name.endsWith('.mp3') || file.name.endsWith('.m4a') || file.name.endsWith('.wav');
                    const isVideo = file.name.endsWith('.mp4') || file.name.endsWith('.avi') || file.name.endsWith('.webm');

                    if ( isAudio || isVideo )
                    {
                        const mediaElement = document.createElement(isAudio ? 'audio' : 'video');
                        mediaElement.src = mediaUrl;
                        mediaElement.controls = true;
                        mediaElement.style.width = '90%';
                        if ( isVideo ) mediaElement.style.maxWidth = '300px';

                        const container = document.getElementById('media-container');
                        container.innerHTML = '';
                        container.appendChild(mediaElement);
                    }
                    else
                    {
                        console.error('지원하지 않는 파일 형식입니다:', file.name);
                    }
                }
                else
                {
                    console.error('응답이 Blob 형식이 아닙니다.', res);
                }
            }
        },
        async onClickUploadFile(file)
        {
            try
            {
                const res = await this.$comm.RequestFILE(this.$i18n.locale, '/v1/download', {
                    userid: this.paramData.userid,
                    contract_id: this.paramData.contract_id,
                    filename: file.name,
                });

                if ( res )
                {
                    if ( res instanceof Blob )
                    {
                        const downloadUrl = URL.createObjectURL(res);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', file.name);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(downloadUrl);
                    }
                    else
                    {
                        console.error('응답이 Blob 형식이 아닙니다.', res);
                    }
                }
            }
            catch (error)
            {
                console.error('파일 다운로드 실패:', error);
            }
        },
        async onClickRejectSign()
        {
            try
            {
                this.$emitter.emit('show-loading');

                var pos;
                try {
                    pos = await this.$utils.getLocation();
                    const address = await this.$utils.getAddress(pos.lat, pos.lng);
                    pos.place = address;
                } catch (err) {
                    console.error(err);
                    pos = {lat: 0, lng: 0, place: []};
                }

                await this.requestRejectSign(pos);
                this.isSigned = true;
            }
            catch (err) { console.error(err); }
            finally
            {
                this.$emitter.emit('hide-loading');
                this.show_dialog = false;
            }
        },
        onClickReject()
        {
            this.show_dialog = true;
        },
        async onClickSign()
        {
            this.show_modal = true;
            this.setTimeAndGPS();

            if ( this.timer != undefined ) clearInterval(this.timer);
            this.timer = setInterval(() => { this.setTimeAndGPS(); }, 1000);
        },
        async setTimeAndGPS()
        {
            const timestamp = Date.now();
            this.current_time = this.$utils.printDateTime(timestamp);

            if ( this.gps_info != 'X' && (this.gps_info == '' || timestamp % 2 == 0) )
            {
                try {
                    const pos = await this.$utils.getLocation();
                    this.gps_info = `${pos.lat}, ${pos.lng}`;
                } catch (err) {
                    this.gps_info = 'X';
                }
            }
        },
        closeModal()
        {
            this.isAnimating = true;
            this.show_modal = false;

            if ( this.timer != undefined ) clearInterval(this.timer);
        },
        handleAnimationEnd()
        {
            if ( !this.show_modal )
            {
                this.isAnimating = false;
            }
        },
        onClickSignMark()
        {
            if ( this.phone.step != 'verify' ) return;
            this.signature_action = true;
        },
        onSignatureClear()
        {
            this.$refs.signature.clear();
            this.signature_action = false;
        },
        async onClickPhoneCode()
        {
            if ( this.phone.num.length <= 5 ) return;

            if ( this.phone.step == '' || (this.phone.step == 'code' && this.phone.remain == 0) )
            {
                this.$emitter.emit('show-loading');
                await this.requestPhoneSign('code', this.phone.ncode, this.phone.num);
                this.$emitter.emit('hide-loading');
            }
        },
        async onClickPhoneVerify()
        {
            if ( this.phone.step != 'code' ) return;
            if ( this.phone.certno.length != 6 ) return;

            this.$emitter.emit('show-loading');
            await this.requestPhoneSign('verify', this.phone.ncode, this.phone.num, this.phone.certno);
            this.$emitter.emit('hide-loading');
        },
        async onClickProcess()
        {
            if ( !this.signature_action ) return;

            try
            {
                this.$emitter.emit('show-loading');

                var pos;
                try {
                    pos = await this.$utils.getLocation();
                    const address = await this.$utils.getAddress(pos.lat, pos.lng);
                    pos.place = address;
                } catch (err) {
                    console.error(err);
                    pos = {lat: 0, lng: 0, place: []};
                }

                const signImage = this.$refs.signature.save('image/jpeg');
                await this.requestSaveSign(signImage, pos);
            }
            catch (err) { console.error(err); }
            finally { this.$emitter.emit('hide-loading'); }
        },
        async requestPhoneSign(step, ncode, phone, code = '')
        {
            const res = await this.$comm.RequestPOST(this.$i18n.locale, '/v1/contract/phonesign', {
                step: step,
                ncode: ncode,
                phone: phone,
                code: code,
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email
            });
            if ( res.code == 'SUCCESS' )
            {
                if ( step == 'code' )
                {
                    this.phone.step = 'code';
                    this.phone.expire = res.data.expire;
                    if ( res.data.code != undefined )
                    {
                        this.phone.certno = res.data.code;
                    }
                    this.phoneRemain();
                }
                else if ( step == 'verify' )
                {
                    if ( res.data.ok == 'OK' )
                    {
                        this.phone.step = 'verify';
                        this.phone.remain = 0;
                    }
                }
            }
            else
            {
                alert(res.message);
            }
        },
        async requestSaveSign(signImage, gpsInfo)
        {
            var reqData = {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email,
                gps: gpsInfo
            };

            if ( this.contractInfo.use_phone_cert == 'Y' )
            {
                reqData.phone = '+' + this.phone.ncode + '-' + this.phone.num;
                reqData.use_phone_cert = true;
            }
            else
            {
                reqData.use_phone_cert = false;
            }

            const res = await this.$comm.RequestPOSTwithFile(this.$i18n.locale, '/v1/contract/savesign', reqData, this.$utils.base64ToBlob(signImage), this.paramData.email + '.jpeg');
            if ( res.code == 'SUCCESS' )
            {
                this.isSigned = true;
                this.closeModal();
            }
            else
            {
                alert(res.message);
            }
        },
        async requestRejectSign(gpsInfo)
        {
            var reqData = {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email,
                gps: gpsInfo
            };
            const res = await this.$comm.RequestPOST(this.$i18n.locale, '/v1/contract/rejectsign', reqData);

            if ( res.code == 'SUCCESS' )
            {
                //this.$router.push('/sign/upload/fail');
            }
            else
            {
                alert(res.message);
            }
        },
        async requestContractInfo()
        {
            const res = await this.$comm.RequestGET(this.$i18n.locale, '/v1/contract/info', {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email,
                usefile: 'Y'
            });
            if ( res.code == 'SUCCESS' )
            {
                this.actionContractInfo(res.data);
                if ( this.contractInfo.participant.me.status != 'waiting' ) this.isSigned = true;

                if ( this.contractInfo.product.id == 'audio' || this.contractInfo.product.id == 'video' || this.contractInfo.product.id == 'mobile' )
                {
                    if ( this.contractInfo.files.length == 1 )
                    {
                        await this.playMediaFile(this.contractInfo.files[0]);
                    }
                    else
                    {
                        console.log('Error:', this.contractInfo);
                    }
                }
            }
            else
            {
                alert(res.message);
            }
        },

    },
    created()
    {
        if ( this.contractInfo.use_phone_cert != 'Y' )
        {
            this.phone.step = 'verify';
        }
    },
    mounted()
    {
        this.requestContractInfo();
    },
    components:
    {
        Header,
        Tips
    }
}
</script>

<style scoped>
.wupload { position: absolute; top: 0; left:0; right:0; height: 100%; }
.wupload .users { display: flex; justify-content: space-around; list-style: none; margin-top: 24px; }
.wupload .users li { display: flex; flex-direction: column; align-items: center; }
.wupload .users li span { color: #0C0C0D; font-size: 14px; margin-top: 8px; margin-bottom: 6px; }
.wupload .users li .waiting { background: url(@/assets/images/signature_waiting.svg) no-repeat left; background-size: contain; color: #7E8185; font-size: 14px; padding-left: 24px; }
.wupload .users li .complete { background: url(@/assets/images/signature_complete.svg) no-repeat left; background-size: contain; color: #1255BA; font-size: 14px; padding-left: 24px; }
.wupload .users li .reject { background: url(@/assets/images/signature_reject.svg) no-repeat left; background-size: contain; color: #C41919; font-size: 14px; padding-left: 24px; }

.wupload .media { min-height: 120px; margin: 24px 0; border-top: 0.5px solid #E3E5E8; border-bottom: 0.5px solid #E3E5E8; }
.wupload .media .audio { padding: 30px 0; position:relative; display:flex; justify-content:center; z-index:1; }
.wupload .media .video { padding: 8px 0; position:relative; display:flex; justify-content:center; z-index:1; }
.wupload .media .upload { padding: 24px 10%; }
.wupload .media .upload .item { display: flex; align-content: center; margin: 10px 0; cursor: pointer; }
.wupload .media .upload .item i { width: 24px; height: 24px; }
.wupload .media .upload .item i.image { color: green; }
.wupload .media .upload .item i.videocam { color: #C41919 }
.wupload .media .upload .item i.play_circle { color: #1255BA }
.wupload .media .upload .item i.description { color: #E08F15; }

.wupload .tips { position: absolute; left: 0; right: 0; bottom: 88px; }

.wupload .buttons { display: flex; position: absolute; left: 0; right: 0; bottom: 16px; }
.wupload .buttons.wait { display: block; }
.wupload .buttons .reject { width: 38%; margin-right: 2%; }
.wupload .buttons .sign { width: 60%; }
.wupload .buttons .wait { width: 100%; background: #7E8185; color: white; }

.modalsheet { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 99; }
.modalsheet .back { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: #000; opacity: 0.8; }
.modalsheet .sheet { position: absolute; top: 48px; left: 0; right: 0; bottom: 0; background-color: white; border-radius: 48px 48px 0 0; transform: translateY(100%); opacity: 0; animation-fill-mode: forwards; padding: 0 5%; }
.modalsheet .sheet .line { width: 50%; height: 4px; margin: auto; margin-top: 8px; margin-bottom: 32px; border-radius: 4px; background-color: #E3E5E8; }
.modalsheet .sheet .phonepad { margin: 16px 0; }
.modalsheet .sheet .phonepad .item { display: flex; justify-content: space-between; margin: 8px 0; }
.modalsheet .sheet .phonepad .inputbox { display: flex; align-items: center; border: 0.5px solid #7E8185; border-radius: 8px; padding-left: 12px; }
.modalsheet .sheet .phonepad .inputbox span { font-weight: 500; color: #0C0C0D; }
.modalsheet .sheet .signpad { position: relative; height: 250px; border: 0.5px solid #7E8185; border-radius: 8px; margin: 8px 0; padding: 4px; }
.modalsheet .sheet .signpad .watermark { position: absolute; top: 0; left: 0; right: 0; bottom: 0; font-size: 32px; font-weight: 800; color: #E3E5E8; text-align: center; line-height: 250px; }
.modalsheet .sheet .signpad .close { position: absolute; top: 10px; right: 10px; width: 24px; height: 24px; background-color: #7E8185; }
.modalsheet .sheet .info { display: flex; justify-content: space-between; font-size: 12px; padding: 0 8px; }
.modalsheet .sheet .signbtn { position: absolute; left: 5%; right: 5%; bottom: 16px; }

.rejectsign { position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 99; }
.rejectsign .backpan { position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0, 0, 0, 0.45); }
.rejectsign .dialog { display: flex; flex-direction: column; align-items: center; position: absolute; top: 30%; left: 10%; right: 10%; background-color: white; border-radius: 16px; padding: 24px; }
.rejectsign .dialog .title { font-size: 18px; margin: 16px; }
.rejectsign .dialog .subtitle { font-weight: 500; }
.rejectsign .dialog .btns { display: flex; justify-content: space-between; margin-top: 24px; width: 100%; }
.rejectsign .dialog .btns button { width: 48%; }
.rejectsign .dialog .btns button.red { background-color: #C41919; color: #FFF; }


.slide-in { animation: slideUp 0.2s ease forwards; }
.slide-out { animation: slideDown 0.2s ease forwards; }

@keyframes slideUp {
  0% { transform: translateY(100%); opacity: 1; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes slideDown {
  0% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(100%); opacity: 1; }
}
</style>
